<template>
<b-row>
    <b-col cols="12">
        <p>We can send you email and sms notifications whenever your account credits go lower than the level you specify below.</p>
    </b-col>

    <b-col cols="12">
        <b-card>
            <b-form @submit.prevent>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Your Name/Organization" label-for="helperInput" description="Find helper text here for given textbox.">
                            <b-form-input id="helperInput" placeholder="Name" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Email Address" label-for="basicInput">
                            <b-form-input id="basicInput" placeholder="Enter Your Email" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Keywords To Request (comma separated)">
                            <b-form-tags v-model="emailList" input-id="tags-separators" separator=" ,;" placeholder="Enter new emails separated by space, comma or semicolon" no-add-on-enter class="mb-2" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Shortcodes" label-for="vi-first-name">
                            <b-form-select v-model="selected" :options="options" />

                        </b-form-group>
                    </b-col>
                    <!-- reset and submit -->
                    <b-col cols="12">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                            Reset
                        </b-button>
                    </b-col>

                </b-row>
            </b-form>
        </b-card>
    </b-col>

</b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BFormSelect,
    BCardText,
    BFormTags
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        vSelect,
        BCard,
        BButton,
        BFormSelect,
        BCardText,
        BFormTags
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            dir: 'ltr',
            emailList: ['Good', 'Best'],
            selected: "",
            selecteds: [{
                    title: 'Good'
                },
                {
                    title: 'Best'
                },
            ],
            emailSelected: [],
            emailOption: [{
                    value: null,
                    text: 'Please select keywords'
                },
                {
                    value: 'Good',
                    text: 'Good'
                },
                {
                    value: 'Best',
                    text: 'Best'
                },
            ],
            options: [{
                    value: null,
                    text: 'Please select an shortcode'
                },
                {
                    value: '40437',
                    text: '40437'
                },
                {
                    value: '12354',
                    text: '12354'
                },
            ],
        }
    },
}
</script>
